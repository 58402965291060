import React, { useEffect } from "react"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { useInView } from "react-intersection-observer"

export type HandleTrackingClickType = (() => Promise<void>) | undefined

export type AnalyticProps = {
  tag: string
  innerRef: (node?: Element | null | undefined) => void
  inView: boolean
  handleTrackingClick: HandleTrackingClickType
}

export const withSection =
  (Component: React.FC<any>) =>
  ({ name, analytics, ...props }: any) => {
    const { trackPromoImpression, trackPromoClick } = useAnalytics()
    const [ref, inView, entry] = useInView()

    useEffect(() => {
      if (inView && analytics) trackPromoImpression(analytics)
    }, [inView, analytics, trackPromoImpression])

    Component.displayName = name
    return (
      <Component
        {...props}
        innerRef={ref}
        inView={inView}
        handleTrackingClick={analytics ? () => trackPromoClick(analytics) : undefined}
        entry={entry}
      />
    )
  }
